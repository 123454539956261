<template>
  <div id="watermark">
    <top-bar :title="'房屋信息'" :left="true"></top-bar>
    <div class="addBtn" @click="goAdd" style="right: 6px" v-if="relationship == 1">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <div class="list-info">
      <div class="topBox">
        <div class="house-fullName">{{ houseInfo.fullName }}</div>
        <div class="house-label">
          <div class="house-label__item" v-for="(item, index) in houseInfo.useTypeStr" :key="index">{{ item }}</div>
        </div>
        <div class="house-num">
          <div class="house-num__item">
            <div><span style="font-size:16px">{{ totalNum }}</span> 人</div>
            <div style="color: #A8C1FF">总人数</div>
          </div>
          <div class="house-num__item">
            <div><span style="font-size:16px">{{ totalNum - migrantNum }}</span> 人</div>
            <div style="color: #A8C1FF">常住人口</div>
          </div>
          <div class="house-num__item">
            <div><span style="font-size:16px">{{ migrantNum }}</span> 人</div>
            <div style="color: #A8C1FF">流动人口</div>
          </div>
        </div>
      </div>
      <div class="cont">
        <van-list offset="10">
          <div class="list-item" v-for="item in dataList" :key="item.id"
            @click="goRes(item.userId, item.relationship, item.relationshipStr)">
            <div class="title">
              <div class="title-left">
                {{ item.registryTypeStr }}
              </div>
              <div class="title-right" :style="{ color: item.relationship == 1 ? '#FE5E5E' : '#3E7EFE' }">
                {{ item.relationshipStr }}
              </div>
            </div>
            <div class="content">
              <img :src="item.sex == 1 ? manHeadImg : womanHeadImg" class="content-img">
              <div class="content-text">
                <div class="top-text">{{ item.userName }}<span style="color:#999;font-size:13px"> / {{ item.age }} /
                    {{ item.sex == '1' ? '男' : '女' }}</span></div>
                <div class="bottom-text">{{ item.mobile }}</div>
                <div class="bottom-text">{{ item.idNumber }}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon" />
              <van-button v-if="item.relationshipStr != '本人' && (relationship == 1 || item.userId == $userId)" plain type="info" size="small" class="btn"
                @click.stop="moveAway(item.id)">搬离</van-button>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import { desensitization } from '@/utils/utils'
import { getImageStream } from '@/utils/index'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'house-info',
  components: {
    topBar
  },
  data() {
    return {
      dataList: [],
      houseInfo: {
        fullName: '',
        useTypeStr: []
      },
      totalNum: 0,
      relationship: ''
    }
  },
  computed: {
    ...mapState(['houseId']),
    ...mapState(['migrantNum']),
    topImg() {
      return getImageStream('files/wx/images/content/house_index.png')
    },
    manHeadImg() {
      return getImageStream('files/wx/images/content/headImgMan.png')
    },
    womanHeadImg() {
      return getImageStream('files/wx/images/content/headImgWoman.png')
    }
  },
  methods: {
    ...mapMutations,
    getDataList() {
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/resident/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          houseId: this.houseId,
          subarea: '',
          subareaName: '',
          fullName: ''
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          data.page.list.forEach(item => {
            item.mobile = desensitization(item.mobile)
            item.idNumber = desensitization(item.idNumber)
            if (this.$userId == item.userId) {
              this.relationship = item.relationship
            }
          })
          this.dataList = data.page.list
          this.totalNum = data.page.list.length

        }
        this.$toast.clear()
      }, err => { this.$toast.clear() })
    },
    init() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.houseId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          if (data.buildingHouse.fullName.indexOf("-") == -1) {
            data.buildingHouse.fullName = data.buildingHouse.fullName + "幢";
          } else {
            data.buildingHouse.fullName = data.buildingHouse.fullName.replace("-", "幢");
            data.buildingHouse.fullName = data.buildingHouse.fullName.replace("-", "单元");
            data.buildingHouse.fullName = data.buildingHouse.fullName + "室";
          }
          this.houseInfo = data.buildingHouse
          switch (data.buildingHouse.useType) {
            case 0: this.houseInfo.useTypeStr = ["未"]; break;
            case 1: this.houseInfo.useTypeStr = ["自"]; break;
            case 2: this.houseInfo.useTypeStr = ["租"]; break;
            case 3: this.houseInfo.useTypeStr = ["半"]; break;
            case 4: this.houseInfo.useTypeStr = ["商"]; break;
            case 5: this.houseInfo.useTypeStr = ["拆"]; break;
            case 6: this.houseInfo.useTypeStr = ["空"]; break;
          }
        }
        this.$toast.clear()
      }, err => { this.$toast.clear() })
    },
    moveAway(id) {
      this.$dialog.confirm({
        title: '搬离',
        message: '确认该人员搬离吗',
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl('/wxapp/user/res/houseRes/moveOut'),
            method: 'post',
            params: this.$http.adornParams({
              id: id
            })
          }).then(({ data }) => {
            if (data.code == 0) {
              this.$toast.success({ message: '成功' })
              this.init()
              this.getDataList()
            } else {
              this.$toast.fail({ message: data.msg })
            }
          }, err => { this.$toast.fail({ message: err }) })
        })
    },
    goAdd() {
      this.$router.push({ path: '/userRes-info', query: { houseId: this.houseInfo.id } })
    },
    goRes(userId, relationship, relationshipStr) {
      const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 13]
      const flag = arr.indexOf(this.relationship)
      //直系亲属 可以看所有人信息的列表
      //本人可查看自己信息
      // 二房东可以查看租客信息
      if (flag != -1 || this.$userId == userId || (this.relationship == 14 && relationship == 10)) {
        this.$router.push({ path: '/userRes-info', query: { userId, houseId: this.houseInfo.id, relationship, relationshipStr } })
      } else {
        this.$toast.fail('无权查看信息')
      }
    }
  },
  created() {
    this.init()
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.info {
  padding-bottom: 60px;
}

.topBox {
  width: 694px;
  height: 231px;
  margin: 20px 28px;
  background: url('~@/assets/img/house-info-top.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;

  .house-fullName {
    position: absolute;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
  }

  .house-label {
    position: absolute;
    top: 82px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;

    .house-label__item {
      width: 42px;
      line-height: 42px;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.3);
      border: 1px solid #FFFFFF;
      border-radius: 5px;
      text-align: center;
    }
  }

  .house-num {
    width: 100%;
    position: absolute;
    top: 149px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;

    .house-num__item {
      margin-left: 68px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin: 0;
      }
    }
  }
}

.list-item {
  &:nth-child(2) {
    margin-top: 20px !important;
  }

  .bottom-text {
    color: #999 !important;
    margin-top: 8px !important;
    font-size: 24px !important;
  }

  .btn {
    position: absolute;
    right: 60px;
  }
}
</style>
